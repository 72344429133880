import React from 'react';
import { Dialog } from '@headlessui/react';
import CustomerForm from './CustomerForm';

function CustomerEditModal({ isOpen, onClose, customer, onSubmit }) {
  const handleSubmit = (formData) => {
    if (customer) {
      // Güncelleme işlemi için id ve data ayrı gönder
      onSubmit({ id: customer._id, data: formData });
    } else {
      // Yeni müşteri ekleme için sadece data gönder
      onSubmit(formData);
    }
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto max-w-xl w-full bg-white dark:bg-gray-800 rounded-lg shadow-xl p-6">
          <Dialog.Title className="text-lg font-medium text-gray-900 dark:text-white mb-4">
            {customer ? 'Müşteri Düzenle' : 'Yeni Müşteri'}
          </Dialog.Title>

          <CustomerForm
            initialData={customer}
            onSubmit={handleSubmit}
            onCancel={onClose}
          />
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}

export default CustomerEditModal; 