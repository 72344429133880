import React, { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { PlusIcon, CubeIcon } from '@heroicons/react/24/outline';
import axiosInstance from '../utils/axios';
import LoadingSpinner from '../components/LoadingSpinner';
import ProductEditModal from '../components/ProductEditModal';
import { toast } from 'react-toastify';

function Products() {
  const queryClient = useQueryClient();
  const [showForm, setShowForm] = useState(false);
  const [editingProduct, setEditingProduct] = useState(null);

  // Ürünleri getir
  const { data: products, isLoading: productsLoading } = useQuery('products', async () => {
    const response = await axiosInstance.get('/products');
    return response.data;
  });

  // Müşterileri getir
  const { data: customers, isLoading: customersLoading } = useQuery('customers', async () => {
    const response = await axiosInstance.get('/customers');
    return response.data;
  });

  // Yeni ürün oluştur
  const createProductMutation = useMutation(
    async (newProduct) => {
      try {
        const response = await axiosInstance.post('/products', {
          ...newProduct,
          price: {
            amount: parseFloat(newProduct.price.amount),
            currency: newProduct.price.currency
          }
        });
        return response.data;
      } catch (error) {
        console.error('Ürün ekleme hatası:', error.response?.data);
        throw error;
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('products');
        setShowForm(false);
        toast.success('Ürün başarıyla eklendi');
      },
      onError: (error) => {
        toast.error(error.response?.data?.message || 'Ürün eklenirken bir hata oluştu');
      }
    }
  );

  // Ürün güncelle
  const updateProductMutation = useMutation(
    async ({ productId, data }) => {
      try {
        const response = await axiosInstance.put(`/products/${productId}`, data);
        return response.data;
      } catch (error) {
        console.error('Ürün güncelleme hatası:', error.response?.data);
        throw error;
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('products');
        setEditingProduct(null);
        setShowForm(false);
        toast.success('Ürün başarıyla güncellendi');
      },
      onError: (error) => {
        toast.error(error.response?.data?.message || 'Ürün güncellenirken bir hata oluştu');
      }
    }
  );

  // Ürün sil
  const deleteProductMutation = useMutation(
    async (productId) => {
      return axiosInstance.delete(`/products/${productId}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('products');
        toast.success('Ürün başarıyla silindi');
      }
    }
  );

  // Form submit handler
  const handleSubmit = (formData) => {
    if (editingProduct) {
      updateProductMutation.mutate({
        productId: editingProduct._id,
        data: formData
      });
    } else {
      createProductMutation.mutate(formData);
    }
  };

  if (productsLoading || customersLoading) return <LoadingSpinner />;

  return (
    <div className="container mx-auto px-4 py-8 mt-16">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Ürünler</h1>
        <button
          onClick={() => {
            setEditingProduct(null); // Yeni ürün eklerken editing state'ini temizle
            setShowForm(true);
          }}
          className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
        >
          <PlusIcon className="h-5 w-5 mr-2" />
          Yeni Ürün
        </button>
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-lg shadow overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
          <thead className="bg-gray-50 dark:bg-gray-700">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                Ürün
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                Müşteri
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                Tarihler
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                Durum
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                İşlemler
              </th>
            </tr>
          </thead>
          <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
            {products?.map((product) => (
              <tr key={product._id}>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 h-10 w-10">
                      <CubeIcon className="h-10 w-10 text-gray-400" />
                    </div>
                    <div className="ml-4">
                      <div className="text-sm font-medium text-gray-900 dark:text-white">
                        {product.name}
                      </div>
                      <div className="text-sm text-gray-500 dark:text-gray-400">
                        {product.price?.amount?.toLocaleString('tr-TR', {
                          style: 'currency',
                          currency: product.price?.currency || 'TRY'
                        })}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900 dark:text-white">
                    {product.customer?.name}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900 dark:text-white">
                    {new Date(product.startDate).toLocaleDateString()}
                  </div>
                  <div className="text-sm text-gray-500 dark:text-gray-400">
                    {product.endDate && new Date(product.endDate).toLocaleDateString()}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                    product.status === 'active'
                      ? 'bg-green-100 text-green-800'
                      : 'bg-red-100 text-red-800'
                  }`}>
                    {product.status === 'active' ? 'Aktif' : 'Pasif'}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                  <button
                    onClick={() => {
                      setEditingProduct(product);
                      setShowForm(true);
                    }}
                    className="text-blue-600 hover:text-blue-900 mr-4"
                  >
                    Düzenle
                  </button>
                  <button
                    onClick={() => {
                      if (window.confirm('Bu ürünü silmek istediğinizden emin misiniz?')) {
                        deleteProductMutation.mutate(product._id);
                      }
                    }}
                    className="text-red-600 hover:text-red-900"
                  >
                    Sil
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Ürün Ekleme/Düzenleme Modal */}
      {showForm && (
        <ProductEditModal
          isOpen={true}
          onClose={() => {
            setShowForm(false);
            setEditingProduct(null);
          }}
          product={editingProduct}
          customers={customers}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
}

export default Products; 