import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { 
  UserCircleIcon, 
  PhoneIcon, 
  EnvelopeIcon,
  MapPinIcon,
  PlusIcon,
  CubeIcon,
  ArrowLeftIcon,
  XMarkIcon,
  PencilIcon,
  TrashIcon
} from '@heroicons/react/24/outline';
import axiosInstance from '../utils/axios';
import LoadingSpinner from '../components/LoadingSpinner';
import { toast } from 'react-toastify';
import ProductForm from '../components/ProductForm';

function CustomerDetail() {
  const { customerId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [showProductForm, setShowProductForm] = useState(false);
  const [productForm, setProductForm] = useState({
    name: '',
    description: '',
    startDate: '',
    endDate: ''
  });
  const [editingProduct, setEditingProduct] = useState(null);

  // Müşteri detaylarını getir
  const { data: customerData, isLoading, error } = useQuery(
    ['customer', customerId],
    async () => {
      try {
        const response = await axiosInstance.get(`/customers/${customerId}`);
        return response.data.data;
      } catch (error) {
        console.error('Müşteri detayları alınırken hata:', error);
        throw error;
      }
    },
    {
      onError: (error) => {
        toast.error(
          error.response?.data?.message || 
          'Müşteri detayları alınırken bir hata oluştu'
        );
        navigate('/customers');
      }
    }
  );

  // Yeni ürün ekleme
  const createProductMutation = useMutation(
    async (newProduct) => {
      const response = await axiosInstance.post('/products', {
        ...newProduct,
        customer: customerId
      });
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['customer', customerId]);
        setShowProductForm(false);
        toast.success('Ürün başarıyla eklendi');
      },
      onError: (error) => {
        toast.error(
          error.response?.data?.message || 
          'Ürün eklenirken bir hata oluştu'
        );
      }
    }
  );

  // Ürün güncelleme mutation'ı
  const updateProductMutation = useMutation(
    async ({ productId, data }) => {
      const response = await axiosInstance.put(`/products/${productId}`, data);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['customer', customerId]);
        setEditingProduct(null);
        setShowProductForm(false);
        toast.success('Ürün başarıyla güncellendi');
      },
      onError: (error) => {
        toast.error(
          error.response?.data?.message || 
          'Ürün güncellenirken bir hata oluştu'
        );
      }
    }
  );

  // Ürün silme mutation'ı
  const deleteProductMutation = useMutation(
    async (productId) => {
      const response = await axiosInstance.delete(`/products/${productId}`);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['customer', customerId]);
        toast.success('Ürün başarıyla silindi');
      },
      onError: (error) => {
        toast.error(
          error.response?.data?.message || 
          'Ürün silinirken bir hata oluştu'
        );
      }
    }
  );

  const handleProductSubmit = (e) => {
    e.preventDefault();
    createProductMutation.mutate(productForm);
  };

  if (isLoading) return <LoadingSpinner />;
  if (error || !customerData) return null;

  return (
    <div className="container mx-auto px-4 py-8 max-w-6xl">
      {/* Geri Dön Butonu */}
      <div className="mb-6">
        <button
          onClick={() => navigate('/customers')}
          className="flex items-center text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-200"
        >
          <ArrowLeftIcon className="h-5 w-5 mr-2" />
          Müşterilere Dön
        </button>
      </div>

      {/* Müşteri Başlık Kartı */}
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 mb-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <UserCircleIcon className="h-16 w-16 text-gray-400" />
            <div className="ml-4">
              <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
                {customerData?.name}
              </h1>
              <div className="mt-2 flex items-center space-x-4">
                <div className="flex items-center text-gray-600 dark:text-gray-400">
                  <EnvelopeIcon className="h-5 w-5 mr-2" />
                  {customerData?.email}
                </div>
                <div className="flex items-center text-gray-600 dark:text-gray-400">
                  <PhoneIcon className="h-5 w-5 mr-2" />
                  {customerData?.phone}
                </div>
              </div>
            </div>
          </div>
          <div>
            <span className={`px-3 py-1 rounded-full text-sm font-semibold ${
              customerData?.status === 'active'
                ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200'
                : 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200'
            }`}>
              {customerData?.status === 'active' ? 'Aktif' : 'Pasif'}
            </span>
          </div>
        </div>
        <div className="mt-4 flex items-center text-gray-600 dark:text-gray-400">
          <MapPinIcon className="h-5 w-5 mr-2" />
          {customerData?.address}
        </div>
      </div>

      {/* Ürünler Listesi */}
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
            Müşteri Ürünleri ({customerData?.products?.length || 0})
          </h2>
          <button
            onClick={() => setShowProductForm(true)}
            className="flex items-center px-4 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700 transition-colors"
          >
            <PlusIcon className="h-5 w-5 mr-2" />
            Yeni Ürün Ekle
          </button>
        </div>

        {customerData?.products && customerData.products.length > 0 ? (
          <div className="space-y-4">
            {customerData.products.map((product) => (
              <div 
                key={product._id}
                className="border dark:border-gray-700 rounded-lg p-4 hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors"
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <CubeIcon className="h-6 w-6 text-gray-400" />
                    <div className="ml-3">
                      <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                        {product.name}
                      </h3>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        {product.description}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-4">
                    <div className="text-right">
                      <div className="text-lg font-semibold text-gray-900 dark:text-white">
                        {product.price?.amount?.toLocaleString('tr-TR', {
                          style: 'currency',
                          currency: product.price?.currency || 'TRY'
                        })}
                      </div>
                      <div className="text-sm text-gray-500 dark:text-gray-400">
                        {new Date(product.startDate).toLocaleDateString('tr-TR')} - 
                        {product.endDate ? new Date(product.endDate).toLocaleDateString('tr-TR') : 'Süresiz'}
                      </div>
                    </div>
                    <div className="flex space-x-2">
                      <button
                        onClick={() => {
                          setEditingProduct(product);
                          setShowProductForm(true);
                        }}
                        className="p-2 text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300"
                        title="Düzenle"
                      >
                        <PencilIcon className="h-5 w-5" />
                      </button>
                      <button
                        onClick={() => {
                          if (window.confirm('Bu ürünü silmek istediğinizden emin misiniz?')) {
                            deleteProductMutation.mutate(product._id);
                          }
                        }}
                        className="p-2 text-red-600 hover:text-red-800 dark:text-red-400 dark:hover:text-red-300"
                        title="Sil"
                      >
                        <TrashIcon className="h-5 w-5" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center py-8 text-gray-500 dark:text-gray-400">
            Bu müşteriye ait ürün bulunmamaktadır.
          </div>
        )}
      </div>

      {/* Ürün Ekleme/Düzenleme Modalı */}
      {showProductForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg p-6 w-full max-w-2xl">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                {editingProduct ? 'Ürün Düzenle' : 'Yeni Ürün Ekle'}
              </h3>
              <button
                onClick={() => {
                  setShowProductForm(false);
                  setEditingProduct(null);
                }}
                className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
              >
                <XMarkIcon className="h-6 w-6" />
              </button>
            </div>
            
            <ProductForm
              customerId={customerId}
              initialData={editingProduct}
              onSubmit={(formData) => {
                if (editingProduct) {
                  updateProductMutation.mutate({
                    productId: editingProduct._id,
                    data: formData
                  });
                } else {
                  createProductMutation.mutate(formData);
                }
              }}
              onCancel={() => {
                setShowProductForm(false);
                setEditingProduct(null);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default CustomerDetail; 