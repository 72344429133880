import React from 'react';

function LoadingSpinner({ size = "large" }) {
  const sizeClasses = {
    small: "h-6 w-6",
    medium: "h-8 w-8",
    large: "h-12 w-12"
  };

  return (
    <div className="flex justify-center items-center h-64">
      <div className={`animate-spin rounded-full border-b-2 border-primary-600 ${sizeClasses[size]}`}></div>
    </div>
  );
}

export default LoadingSpinner; 