import React from 'react';
import { motion } from 'framer-motion';

function Logo() {
  return (
    <motion.div
      initial={{ scale: 0.5, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      transition={{ duration: 0.5 }}
      whileHover={{ scale: 1.05 }}
      className="flex items-center"
    >
      <motion.div
        className="bg-primary-600 dark:bg-primary-500 text-white font-bold text-xl w-10 h-10 rounded-lg flex items-center justify-center overflow-hidden"
        whileHover={{ rotate: 360 }}
        transition={{ duration: 0.5 }}
      >
        <motion.span
          initial={{ y: -20 }}
          animate={{ y: 0 }}
          transition={{ delay: 0.2, type: "spring", stiffness: 200 }}
        >
          T
        </motion.span>
      </motion.div>
      <motion.span
        className="text-primary-600 dark:text-primary-400 font-bold text-xl ml-2"
        initial={{ x: -20, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ delay: 0.3 }}
      >
        akip
      </motion.span>
    </motion.div>
  );
}

export default Logo; 