import React from 'react';
import { Dialog } from '@headlessui/react';
import ProductForm from './ProductForm';
import { toast } from 'react-toastify';

function ProductEditModal({ isOpen, onClose, product, customers, onSubmit }) {
  const handleSubmit = (formData) => {
    try {
      // Müşteri seçimi kontrolü
      if (!formData.customer) {
        toast.error('Lütfen bir müşteri seçin');
        return;
      }

      // Tarihleri kontrol et
      const startDate = new Date(formData.startDate);
      if (isNaN(startDate.getTime())) {
        toast.error('Geçerli bir başlangıç tarihi girin');
        return;
      }

      if (formData.endDate && !formData.isRecurring) {
        const endDate = new Date(formData.endDate);
        if (isNaN(endDate.getTime()) || endDate <= startDate) {
          toast.error('Geçerli bir bitiş tarihi girin');
          return;
        }
      }

      // Form verilerini hazırla
      const productData = {
        ...formData,
        startDate: startDate.toISOString(),
        endDate: formData.endDate ? new Date(formData.endDate).toISOString() : null,
        price: {
          ...formData.price,
          amount: parseFloat(formData.price.amount)
        }
      };

      onSubmit(productData);
      onClose();
    } catch (error) {
      console.error('Form gönderme hatası:', error);
      toast.error('Form gönderilirken bir hata oluştu');
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto max-w-xl w-full bg-white dark:bg-gray-800 rounded-lg shadow-xl p-6">
          <Dialog.Title className="text-lg font-medium text-gray-900 dark:text-white mb-4">
            {product ? 'Ürün Düzenle' : 'Yeni Ürün'}
          </Dialog.Title>

          <ProductForm
            initialData={product}
            customers={customers}
            onSubmit={handleSubmit}
            onCancel={onClose}
          />
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}

export default ProductEditModal; 