import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

const CURRENCIES = [
  { value: 'TRY', label: '₺ TL' },
  { value: 'USD', label: '$ USD' },
  { value: 'EUR', label: '€ EUR' },
  { value: 'AZN', label: '₼ AZN' }
];

function ProductForm({ customerId, initialData, customers, onSubmit, onCancel }) {
  const [formData, setFormData] = useState({
    name: initialData?.name || '',
    description: initialData?.description || '',
    customer: initialData?.customer || '',
    startDate: initialData?.startDate ? new Date(initialData.startDate).toISOString().split('T')[0] : '',
    endDate: initialData?.endDate ? new Date(initialData.endDate).toISOString().split('T')[0] : '',
    price: {
      amount: initialData?.price?.amount || '',
      currency: initialData?.price?.currency || 'TRY'
    },
    isRecurring: initialData?.isRecurring || false,
    recurringType: initialData?.recurringType || 'monthly',
    paymentDay: initialData?.paymentDay || 1
  });

  // initialData değiştiğinde form verilerini güncelle
  useEffect(() => {
    if (initialData) {
      setFormData({
        name: initialData.name || '',
        description: initialData.description || '',
        customer: initialData.customer || '',
        startDate: initialData.startDate ? new Date(initialData.startDate).toISOString().split('T')[0] : '',
        endDate: initialData.endDate ? new Date(initialData.endDate).toISOString().split('T')[0] : '',
        price: {
          amount: initialData.price?.amount || '',
          currency: initialData.price?.currency || 'TRY'
        },
        isRecurring: initialData.isRecurring || false,
        recurringType: initialData.recurringType || 'monthly',
        paymentDay: initialData.paymentDay || 1
      });
    }
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setFormData(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: type === 'number' ? parseFloat(value) : value
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : value
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Validasyonlar
    if (!formData.name || !formData.startDate || !formData.price.amount) {
      toast.error('Lütfen zorunlu alanları doldurun');
      return;
    }

    // Tarihleri kontrol et
    if (!formData.isRecurring && formData.endDate) {
      const startDate = new Date(formData.startDate);
      const endDate = new Date(formData.endDate);
      if (endDate <= startDate) {
        toast.error('Bitiş tarihi başlangıç tarihinden sonra olmalıdır');
        return;
      }
    }

    // Fiyat kontrolü
    if (!formData.price.amount || formData.price.amount <= 0) {
      toast.error('Lütfen geçerli bir fiyat girin');
      return;
    }

    // Form verilerini gönder
    onSubmit({
      ...formData,
      price: {
        ...formData.price,
        amount: parseFloat(formData.price.amount)
      }
    });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          Ürün Adı
        </label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white sm:text-sm"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          Açıklama
        </label>
        <textarea
          name="description"
          value={formData.description}
          onChange={handleChange}
          rows={3}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white sm:text-sm"
        />
      </div>

      {/* Müşteri seçimi */}
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          Müşteri
        </label>
        <select
          name="customer"
          value={formData.customer}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white sm:text-sm"
          required
        >
          <option value="">Müşteri Seçin</option>
          {customers?.map(customer => (
            <option key={customer._id} value={customer._id}>
              {customer.name}
            </option>
          ))}
        </select>
      </div>

      {/* Tekrarlayan Ödeme Seçenekleri */}
      <div className="space-y-4">
        <div className="flex items-center">
          <input
            type="checkbox"
            id="isRecurring"
            name="isRecurring"
            checked={formData.isRecurring}
            onChange={handleChange}
            className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600"
          />
          <label htmlFor="isRecurring" className="ml-2 block text-sm text-gray-900 dark:text-gray-300">
            Tekrarlayan Ödeme
          </label>
        </div>

        {formData.isRecurring && (
          <>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Tekrarlama Tipi
              </label>
              <select
                name="recurringType"
                value={formData.recurringType}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white sm:text-sm"
              >
                <option value="monthly">Aylık</option>
                <option value="yearly">Yıllık</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Ödeme Günü
              </label>
              <input
                type="number"
                name="paymentDay"
                min="1"
                max="31"
                value={formData.paymentDay}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white sm:text-sm"
                required={formData.isRecurring}
              />
              <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                {formData.recurringType === 'monthly' 
                  ? 'Her ay ödenecek gün (1-31)' 
                  : 'Her yıl ödenecek gün (1-31)'}
              </p>
            </div>
          </>
        )}
      </div>

      {/* Tarih Alanları */}
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Başlangıç Tarihi
          </label>
          <input
            type="date"
            name="startDate"
            value={formData.startDate}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white sm:text-sm"
            required
          />
        </div>
        {!formData.isRecurring && (
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Bitiş Tarihi
            </label>
            <input
              type="date"
              name="endDate"
              value={formData.endDate}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white sm:text-sm"
              required={!formData.isRecurring}
            />
          </div>
        )}
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Fiyat
          </label>
          <input
            type="number"
            name="price.amount"
            value={formData.price.amount}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white sm:text-sm"
            required
            min="0"
            step="0.01"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Para Birimi
          </label>
          <select
            name="price.currency"
            value={formData.price.currency}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white sm:text-sm"
          >
            {CURRENCIES.map(currency => (
              <option key={currency.value} value={currency.value}>
                {currency.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="flex justify-end space-x-3 pt-4">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 dark:bg-gray-700 dark:text-gray-200 dark:border-gray-600 dark:hover:bg-gray-600"
        >
          İptal
        </button>
        <button
          type="submit"
          className="px-4 py-2 text-sm font-medium text-white bg-primary-600 border border-transparent rounded-md shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          {initialData ? 'Güncelle' : 'Ekle'}
        </button>
      </div>
    </form>
  );
}

export default ProductForm; 