import React, { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { PlusIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import axiosInstance from '../utils/axios';
import LoadingSpinner from '../components/LoadingSpinner';
import CustomerEditModal from '../components/CustomerEditModal';
import { toast } from 'react-toastify';

function Customers() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [showForm, setShowForm] = useState(false);
  const [editingCustomer, setEditingCustomer] = useState(null);

  // Müşterileri getir
  const { data: customers, isLoading } = useQuery('customers', async () => {
    const response = await axiosInstance.get('/customers');
    return response.data;
  });

  // Yeni müşteri oluştur
  const createCustomerMutation = useMutation(
    async (newCustomer) => {
      const response = await axiosInstance.post('/customers', newCustomer);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('customers');
        setShowForm(false);
        toast.success('Müşteri başarıyla eklendi');
      },
      onError: (error) => {
        toast.error(error.response?.data?.message || 'Müşteri eklenirken bir hata oluştu');
      }
    }
  );

  // Müşteri güncelle
  const updateCustomerMutation = useMutation(
    async ({ id, data }) => {
      const response = await axiosInstance.put(`/customers/${id}`, data);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('customers');
        setEditingCustomer(null);
        setShowForm(false);
        toast.success('Müşteri başarıyla güncellendi');
      },
      onError: (error) => {
        toast.error(error.response?.data?.message || 'Müşteri güncellenirken bir hata oluştu');
      }
    }
  );

  // Müşteri sil
  const deleteCustomerMutation = useMutation(
    async (customerId) => {
      return axiosInstance.delete(`/customers/${customerId}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('customers');
        toast.success('Müşteri başarıyla silindi');
      }
    }
  );

  // Form submit handler
  const handleSubmit = (formData) => {
    if ('id' in formData) {
      // Güncelleme işlemi
      updateCustomerMutation.mutate(formData);
    } else {
      // Yeni müşteri ekleme
      createCustomerMutation.mutate(formData);
    }
  };

  if (isLoading) return <LoadingSpinner />;

  return (
    <div className="container mx-auto px-4 py-8 mt-16">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Müşteriler</h1>
        <button
          onClick={() => setShowForm(true)}
          className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
        >
          <PlusIcon className="h-5 w-5 mr-2" />
          Yeni Müşteri
        </button>
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-lg shadow overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
          <thead className="bg-gray-50 dark:bg-gray-700">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                Müşteri
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                İletişim
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                Durum
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                İşlemler
              </th>
            </tr>
          </thead>
          <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
            {customers?.map((customer) => (
              <tr key={customer._id}>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <UserCircleIcon className="h-10 w-10 text-gray-400" />
                    </div>
                    <div className="ml-4">
                      <div 
                        className="text-sm font-medium text-primary-600 dark:text-primary-400 cursor-pointer hover:text-primary-800"
                        onClick={() => navigate(`/customers/${customer._id}`)}
                      >
                        {customer.name}
                      </div>
                      <div className="text-sm text-gray-500 dark:text-gray-400">
                        {customer.email}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900 dark:text-gray-100">{customer.phone}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                    customer.status === 'active'
                      ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200'
                      : 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200'
                  }`}>
                    {customer.status === 'active' ? 'Aktif' : 'Pasif'}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                  <div className="flex space-x-2">
                    <button
                      onClick={() => navigate(`/customers/${customer._id}`)}
                      className="text-primary-600 hover:text-primary-900 dark:text-primary-400 dark:hover:text-primary-300"
                    >
                      Detay
                    </button>
                    <button
                      onClick={() => {
                        setEditingCustomer(customer);
                        setShowForm(true);
                      }}
                      className="text-blue-600 hover:text-blue-900"
                    >
                      Düzenle
                    </button>
                    <button
                      onClick={() => {
                        if (window.confirm('Bu müşteriyi silmek istediğinizden emin misiniz?')) {
                          deleteCustomerMutation.mutate(customer._id);
                        }
                      }}
                      className="text-red-600 hover:text-red-900"
                    >
                      Sil
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Müşteri Ekleme/Düzenleme Modal */}
      {showForm && (
        <CustomerEditModal
          isOpen={true}
          onClose={() => {
            setShowForm(false);
            setEditingCustomer(null);
          }}
          customer={editingCustomer}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
}

export default Customers; 