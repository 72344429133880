import React from 'react';
import { useQuery } from 'react-query';
import axiosInstance from '../utils/axios';
import LoadingSpinner from '../components/LoadingSpinner';
import { 
  ChartBarIcon, 
  UserGroupIcon, 
  CubeIcon, 
  BellIcon,
  BanknotesIcon
} from '@heroicons/react/24/outline';
import { format, parseISO } from 'date-fns';
import { tr } from 'date-fns/locale';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Line, Pie } from 'react-chartjs-2';

// Chart.js bileşenlerini kaydet
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

function formatDate(dateString, formatStr = 'dd MMM yyyy') {
  if (!dateString) return '-';
  try {
    return format(parseISO(dateString), formatStr, { locale: tr });
  } catch (error) {
    console.error('Tarih formatı hatası:', error);
    return '-';
  }
}

function Dashboard() {
  const { data: stats, isLoading, error } = useQuery('dashboardStats', async () => {
    const response = await axiosInstance.get('/stats/dashboard');
    return response.data;
  });

  if (isLoading) return <LoadingSpinner />;

  if (error) {
    return (
      <div className="p-6 text-center">
        <h2 className="text-2xl font-semibold text-red-600 dark:text-red-400">
          Hata Oluştu
        </h2>
        <p className="mt-2 text-gray-600 dark:text-gray-400">
          İstatistikler yüklenirken bir hata oluştu.
        </p>
      </div>
    );
  }

  if (!stats || !stats.lists) return null;

  const cards = [
    {
      title: 'Toplam Müşteri',
      value: stats.overview?.totalCustomers || 0,
      icon: UserGroupIcon,
      color: 'bg-blue-500'
    },
    {
      title: 'Toplam Ürün',
      value: stats.overview?.totalProducts || 0,
      icon: CubeIcon,
      color: 'bg-green-500'
    },
    {
      title: 'Aktif Yöneticiler',
      value: stats.overview?.activeAdmins || 0,
      icon: ChartBarIcon,
      color: 'bg-yellow-500'
    },
    {
      title: 'Yaklaşan Son Kullanma',
      value: stats.overview?.expiringProducts || 0,
      icon: BellIcon,
      color: 'bg-red-500'
    },
    {
      title: 'Toplam Kazanç',
      value: new Intl.NumberFormat('tr-TR', { 
        style: 'currency', 
        currency: 'TRY',
        minimumFractionDigits: 2
      }).format(stats.overview?.totalRevenue || 0),
      icon: BanknotesIcon,
      color: 'bg-emerald-500'
    }
  ];

  const {
    recentCustomers = [],
    recentProducts = [],
    recentAdmins = [],
    expiringProducts = [],
    expiredProducts = []
  } = stats.lists;

  // Müşteri artış grafiği için veri
  const lineChartData = {
    labels: stats.customerGrowth?.map(item => item.month) || [],
    datasets: [
      {
        label: 'Yeni Müşteriler',
        data: stats.customerGrowth?.map(item => item.count) || [],
        borderColor: 'rgb(59, 130, 246)',
        backgroundColor: 'rgba(59, 130, 246, 0.5)',
        tension: 0.3
      }
    ]
  };

  // Ürün durumu grafiği için veri
  const pieChartData = {
    labels: stats.productStatusDistribution?.map(item => item._id) || [],
    datasets: [
      {
        data: stats.productStatusDistribution?.map(item => item.count) || [],
        backgroundColor: [
          'rgba(34, 197, 94, 0.5)',  // Yeşil - Aktif
          'rgba(239, 68, 68, 0.5)',  // Kırmızı - Süresi Dolmuş
          'rgba(234, 179, 8, 0.5)',  // Sarı - Beklemede
          'rgba(107, 114, 128, 0.5)' // Gri - İptal Edilmiş
        ],
        borderColor: [
          'rgb(34, 197, 94)',
          'rgb(239, 68, 68)',
          'rgb(234, 179, 8)',
          'rgb(107, 114, 128)'
        ],
        borderWidth: 1
      }
    ]
  };

  // Grafik seçenekleri
  const lineOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1
        }
      }
    }
  };

  const pieOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
        align: 'center',
        labels: {
          padding: 20,
          font: {
            size: 14
          }
        }
      },
      title: {
        display: false
      }
    }
  };

  return (
    <div className="p-6 space-y-8">
      <h2 className="text-2xl font-semibold mb-6 text-gray-800 dark:text-white">
        Dashboard
      </h2>

      {/* İstatistik Kartları */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-6 mb-8">
        {cards.map((card, index) => (
          <div
            key={index}
            className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden"
          >
            <div className="p-6">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-sm font-medium text-gray-600 dark:text-gray-400">
                    {card.title}
                  </p>
                  <p className="text-2xl font-bold text-gray-900 dark:text-white">
                    {card.value}
                  </p>
                </div>
                <div className={`p-3 rounded-full ${card.color} bg-opacity-20`}>
                  <card.icon className={`w-6 h-6 ${card.color.replace('bg-', 'text-')}`} />
                </div>
              </div>
            </div>
            <div className={`h-1 ${card.color}`} />
          </div>
        ))}
      </div>

      {/* Grafikler */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Müşteri Artış Grafiği */}
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <h3 className="text-lg font-semibold mb-4 text-gray-800 dark:text-white">
            Müşteri Artışı
          </h3>
          <div className="h-64">
            <Line data={lineChartData} options={lineOptions} />
          </div>
        </div>

        {/* Ürün Durumu Dağılımı */}
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <h3 className="text-lg font-semibold mb-4 text-gray-800 dark:text-white">
            Ürün Durumu Dağılımı
          </h3>
          <div className="flex items-center justify-center h-64">
            <div className="w-full max-w-lg">
              <Pie data={pieChartData} options={pieOptions} />
            </div>
          </div>
        </div>
      </div>

      {/* Listeler */}
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-6">
        {/* Son Eklenen Müşteriler */}
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg">
          <div className="p-4 border-b border-gray-200 dark:border-gray-700">
            <h3 className="text-lg font-semibold text-gray-800 dark:text-white">
              Son Eklenen Müşteriler
            </h3>
          </div>
          <div className="p-4">
            <div className="overflow-x-auto">
              <table className="min-w-full">
                <thead>
                  <tr className="text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                    <th className="p-2">Müşteri</th>
                    <th className="p-2">Email</th>
                    <th className="p-2">Telefon</th>
                    <th className="p-2">Tarih</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                  {recentCustomers.length > 0 ? (
                    recentCustomers.map((customer) => (
                      <tr key={customer._id}>
                        <td className="p-2">{customer.name}</td>
                        <td className="p-2">{customer.email}</td>
                        <td className="p-2">{customer.phone}</td>
                        <td className="p-2">
                          {formatDate(customer.createdAt)}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" className="p-4 text-center text-gray-500">
                        Henüz müşteri eklenmemiş
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* Son Eklenen Ürünler */}
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg">
          <div className="p-4 border-b border-gray-200 dark:border-gray-700">
            <h3 className="text-lg font-semibold text-gray-800 dark:text-white">
              Son Eklenen Ürünler
            </h3>
          </div>
          <div className="p-4">
            <div className="overflow-x-auto">
              <table className="min-w-full">
                <thead>
                  <tr className="text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                    <th className="p-2">Ürün</th>
                    <th className="p-2">Müşteri</th>
                    <th className="p-2">Başlangıç</th>
                    <th className="p-2">Bitiş</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                  {recentProducts.length > 0 ? (
                    recentProducts.map((product) => (
                      <tr key={product._id}>
                        <td className="p-2">{product.name}</td>
                        <td className="p-2">{product.customer?.name}</td>
                        <td className="p-2">
                          {formatDate(product.startDate)}
                        </td>
                        <td className="p-2">
                          {formatDate(product.endDate)}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" className="p-4 text-center text-gray-500">
                        Henüz ürün eklenmemiş
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* Son Giriş Yapan Yöneticiler */}
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg">
          <div className="p-4 border-b border-gray-200 dark:border-gray-700">
            <h3 className="text-lg font-semibold text-gray-800 dark:text-white">
              Son Giriş Yapan Yöneticiler
            </h3>
          </div>
          <div className="p-4">
            <div className="overflow-x-auto">
              <table className="min-w-full">
                <thead>
                  <tr className="text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                    <th className="p-2">Yönetici</th>
                    <th className="p-2">Email</th>
                    <th className="p-2">Giriş Tarihi</th>
                    <th className="p-2">IP Adresi</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                  {recentAdmins.length > 0 ? (
                    recentAdmins.map((activity) => (
                      <tr key={activity._id}>
                        <td className="p-2">{activity.user?.name}</td>
                        <td className="p-2">{activity.user?.email}</td>
                        <td className="p-2">
                          {formatDate(activity.createdAt, 'dd MMM yyyy HH:mm')}
                        </td>
                        <td className="p-2">{activity.ipAddress}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" className="p-4 text-center text-gray-500">
                        Henüz yönetici girişi yapılmamış
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* Süresi Yaklaşan Ürünler */}
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg">
          <div className="p-4 border-b border-gray-200 dark:border-gray-700">
            <h3 className="text-lg font-semibold text-gray-800 dark:text-white">
              Süresi Yaklaşan Ürünler
            </h3>
          </div>
          <div className="p-4">
            <div className="overflow-x-auto">
              <table className="min-w-full">
                <thead>
                  <tr className="text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                    <th className="p-2">Ürün</th>
                    <th className="p-2">Müşteri</th>
                    <th className="p-2">Son Kullanma</th>
                    <th className="p-2">Kalan Gün</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                  {expiringProducts.length > 0 ? (
                    expiringProducts.map((product) => {
                      const daysLeft = product.endDate ? 
                        Math.ceil((new Date(product.endDate) - new Date()) / (1000 * 60 * 60 * 24)) : 
                        0;
                      return (
                        <tr key={product._id}>
                          <td className="p-2">{product.name}</td>
                          <td className="p-2">{product.customer?.name}</td>
                          <td className="p-2">
                            {formatDate(product.endDate)}
                          </td>
                          <td className="p-2">
                            <span className="px-2 py-1 text-xs font-semibold rounded-full bg-yellow-100 text-yellow-800">
                              {daysLeft} gün
                            </span>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="4" className="p-4 text-center text-gray-500">
                        Henüz süresi yaklaşan ürün eklenmemiş
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* Süresi Dolmuş Ürünler */}
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg">
          <div className="p-4 border-b border-gray-200 dark:border-gray-700">
            <h3 className="text-lg font-semibold text-gray-800 dark:text-white">
              Süresi Dolmuş Ürünler
            </h3>
          </div>
          <div className="p-4">
            <div className="overflow-x-auto">
              <table className="min-w-full">
                <thead>
                  <tr className="text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                    <th className="p-2">Ürün</th>
                    <th className="p-2">Müşteri</th>
                    <th className="p-2">Son Kullanma</th>
                    <th className="p-2">Geçen Gün</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                  {expiredProducts.length > 0 ? (
                    expiredProducts.map((product) => {
                      const daysPassed = product.endDate ?
                        Math.ceil((new Date() - new Date(product.endDate)) / (1000 * 60 * 60 * 24)) :
                        0;
                      return (
                        <tr key={product._id}>
                          <td className="p-2">{product.name}</td>
                          <td className="p-2">{product.customer?.name}</td>
                          <td className="p-2">
                            {formatDate(product.endDate)}
                          </td>
                          <td className="p-2">
                            <span className="px-2 py-1 text-xs font-semibold rounded-full bg-red-100 text-red-800">
                              {daysPassed} gün önce
                            </span>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="4" className="p-4 text-center text-gray-500">
                        Henüz süresi dolmuş ürün eklenmemiş
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard; 